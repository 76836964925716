import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { LightboxModule } from 'ngx-lightbox';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { WelcomePageComponent } from './pages/welcome-page/welcome-page.component';
import { FooterComponent } from './shared/footer/footer.component';
import { PortfolioPageComponent } from './pages/portfolio-page/portfolio-page.component';
import { GalleryComponent } from './shared/gallery/gallery.component';
import { PortfolioMenuPageComponent } from './pages/portfolio-page/portfolio-menu-page/portfolio-menu-page.component';
import { InfoPageComponent } from './pages/info-page/info-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PortfolioWeddingPageComponent } from './pages/portfolio-page/portfolio-wedding-page/portfolio-wedding-page.component';
import { PortfolioPortraitPageComponent } from './pages/portfolio-page/portfolio-portrait-page/portfolio-portrait-page.component';
import { PortfolioNaissancePageComponent } from './pages/portfolio-page/portfolio-naissance-page/portfolio-naissance-page.component';
import { ResponsiveModule } from 'ngx-responsive';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    WelcomePageComponent,
    FooterComponent,
    PortfolioPageComponent,
    GalleryComponent,
    PortfolioMenuPageComponent,
    InfoPageComponent,
    ContactPageComponent,
    PortfolioWeddingPageComponent,
    PortfolioPortraitPageComponent,
    PortfolioNaissancePageComponent
  ],
  imports: [
    MDBBootstrapModule.forRoot(),
    ResponsiveModule.forRoot(),
    BrowserModule,
    FormsModule,
    LightboxModule,
    HttpClientModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
