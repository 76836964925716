import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss']
})
export class WelcomePageComponent implements OnInit {

  constructor() { }

  public welcomePictureUrls = [];

  ngOnInit(): void {
    for ( let i = 1; i <= 6; i++) {
      this.welcomePictureUrls.push('https://tnrphotographe.com/assets/pictures/welcome/welcome-' + i + '.jpg');
    }
  }

}
