<div class='d-none' #shareButtons>
  <share-buttons [theme]="'default'"
    [include]="['facebook','twitter','pinterest']"
    [show]="3"
    [url]="'https://tnrphotographe.com/accueil/'"
    [title]="'TnrPhotographe'"
    [description]="'Galeries TnrPhotographe: https://tnrphotographe.com/portfolio/ .'"
    [image]="'https://tnrphotographe.com/assets/pictures/portfolio/portfolio-wedding/thumb-1.jpg'"
    [tags]="'tnrPhotographe'"
    ></share-buttons>
</div>

<div class="gallery-container" [ngClass]="{'loading-gallery': loadingGallery}">
  <!-- top part -->
  <div class="gallery-row row no-gutters">
    <div class="col-12 col-lg-5 h-100">
      <div class="row no-gutters h-60">
        <div class="col-12 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[0].thumb" (click)="open(0)"/></div></div>
      </div>
      <div class="row no-gutters h-40">
        <div class="col-12 col-lg-6 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[1].thumb" (click)="open(1)"/></div></div>
        <div class="col-12 col-lg-6 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[2].thumb" (click)="open(2)"/></div></div>
      </div>
    </div>
    <div class="col-12 col-lg-7 row no-gutters h-100">
      <div class="col-12 col-lg-9 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[3].thumb" (click)="open(3)"/></div></div>
      <div class="col-12 col-lg-3 h-100">
        <div class="h-30 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[4].thumb" (click)="open(4)"/></div></div>
        <div class="h-70 no-overflow  p-1"><div class="img-container"><img loading="lazy" [src]="albums[5].thumb" (click)="open(5)"/></div></div>
      </div>
    </div>
  </div>
  <!-- Middle part -->
  <div class="gallery-row row no-gutters">
      <div class="col-12 col-lg-5 h-100">
        <div class="row no-gutters h-60">
          <div class="col-12 col-lg-4 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[6].thumb" (click)="open(6)"/></div></div>
          <div class="col-12 col-lg-8 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[7].thumb" (click)="open(7)"/></div></div>
        </div>
        <div class="row no-gutters h-40">
          <div class="col-12 col-lg-6 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[8].thumb" (click)="open(8)"/></div></div>
          <div class="col-12 col-lg-6 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[9].thumb" (click)="open(9)"/></div></div>
        </div>
      </div>
      <div class="col-12 col-lg-7 h-100">
        <div class="row no-gutters h-60">
          <div class="col-12 col-lg-3 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[10].thumb" (click)="open(10)"/></div></div>
          <div class="col-12 col-lg-3 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[11].thumb" (click)="open(11)"/></div></div>
          <div class="col-12 col-lg-6 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[12].thumb" (click)="open(12)"/></div></div>
        </div>
        <div class="row no-gutters h-40">
          <div class="col-12 col-lg-3 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[13].thumb" (click)="open(13)"/></div></div>
          <div class="col-12 col-lg-6 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[14].thumb" (click)="open(14)"/></div></div>
          <div class="col-12 col-lg-3 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[15].thumb" (click)="open(15)"/></div></div>
        </div>
      </div>
    </div>
    <!-- down part -->
    <div class="gallery-row row no-gutters">
        <div class="col-12 col-lg-5 h-100">
          <div class="row no-gutters h-60">
            <div class="col-12 col-lg-8 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[16].thumb" (click)="open(16)"/></div></div>
            <div class="col-12 col-lg-4 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[17].thumb" (click)="open(17)"/></div></div>
          </div>
          <div class="row no-gutters h-40">
            <div class="col-12 col-lg-6 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[18].thumb" (click)="open(18)"/></div></div>
            <div class="col-12 col-lg-6 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[19].thumb" (click)="open(19)"/></div></div>
          </div>
        </div>
        <div class="col-12 col-lg-7 h-100">
          <div class="row no-gutters h-60">
            <div class="col-12 col-lg-6 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[20].thumb" (click)="open(20)"/></div></div>
            <div class="col-12 col-lg-3 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[21].thumb" (click)="open(21)"/></div></div>
            <div class="col-12 col-lg-3 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[22].thumb" (click)="open(22)"/></div></div>
          </div>
          <div class="row no-gutters h-40">
            <div class="col-12 col-lg-3 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[23].thumb" (click)="open(23)"/></div></div>
            <div class="col-12 col-lg-3 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[24].thumb" (click)="open(24)"/></div></div>
            <div class="col-12 col-lg-6 h-100 no-overflow p-1"><div class="img-container"><img loading="lazy" [src]="albums[25].thumb" (click)="open(25)"/></div></div>
          </div>
        </div>
      </div>
</div>
<div class="overlay-loading" *ngIf="loadingGallery">
  <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
  <div>Loading...</div>
</div>
