import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portfolio-naissance-page',
  templateUrl: './portfolio-naissance-page.component.html',
  styleUrls: ['./portfolio-naissance-page.component.scss']
})
export class PortfolioNaissancePageComponent implements OnInit {

  public theme = 'naissance';
  public nbImg = 26;

  constructor() { }

  ngOnInit(): void {
  }

}
