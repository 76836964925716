import { PortfolioPortraitPageComponent } from './pages/portfolio-page/portfolio-portrait-page/portfolio-portrait-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { PortfolioMenuPageComponent } from './pages/portfolio-page/portfolio-menu-page/portfolio-menu-page.component';
import { PortfolioPageComponent } from './pages/portfolio-page/portfolio-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomePageComponent } from './pages/welcome-page/welcome-page.component';
import { GalleryComponent } from './shared/gallery/gallery.component';
import { InfoPageComponent } from './pages/info-page/info-page.component';
import { PortfolioWeddingPageComponent } from './pages/portfolio-page/portfolio-wedding-page/portfolio-wedding-page.component';
import { PortfolioNaissancePageComponent } from './pages/portfolio-page/portfolio-naissance-page/portfolio-naissance-page.component';


const routes: Routes = [
  {
    path: 'accueil', component: WelcomePageComponent
  },
  {
    path: 'portfolio',
    component: PortfolioPageComponent,
    children: [
      {
        path: '',
        component: PortfolioMenuPageComponent
      },
      {
        path: 'mariage',
        component: PortfolioWeddingPageComponent
      },
      {
        path: 'portrait',
        component: PortfolioPortraitPageComponent
      },
      {
        path: 'naissance',
        component: PortfolioNaissancePageComponent
      }
    ]
  },
  {
    path: 'info',
    component: InfoPageComponent
  },
  {
    path: 'contact',
    component: ContactPageComponent
  },
  {
    path: '**',
    redirectTo: '/accueil', pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: '/accueil', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
