<div class="info-container">
  <div class="pp-main">
    <img src="./../../../assets/pictures/infos/info-profil.jpg"/>
  </div>
  <div class="text-content">
  
    <h1>TOKY NIRINA RASOLOHERY</h1>
    <span>
      Bonjour ! <br /> <br /> 
      Je m’appelle Toky Nirina Rasolohery (TNR). Photographe de mariage installé à Montpellier, je mets toute mon énergie pour immortaliser  vos précieux moments de vie (mariage, maternité, anniversaire…) et capturer des instants d’émotions intenses. Mon approche est esthétique et sensible. D’un naturel jovial, je saurai vous mettre en confiance et me faire discret pour raconter et mettre en lumière vos histoires uniques.<br /> <br /> 
      Ma double casquette de photographe et de graphiste me permet de répondre à tout besoin autour de la photographie (book photo en multiple format et tout produit dérivé de la photo…).<br /> <br /> 
      Découvrez mon univers visuel. Vous aimez mon style ? Contactez-moi et je serai disponible pour votre projet photo.
    </span>
  </div>
</div>
