import { MailService } from './../../services/mail.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

  public formSubmitted = false;
  public displaySuccessMessage = false;
  public displayErrorMessage = false;
  public loading = false;
  public name: string;
  public email: string;
  public object: string;
  public message: string;
  public welcomePictureUrls = [];

  constructor(private mailService: MailService) { }

  ngOnInit(): void {
    for ( let i = 1; i <= 3; i++) {
      this.welcomePictureUrls.push('../assets/pictures/contact-caroussel/contact-' + i + '.jpg');
    }
  }

  onSubmit(contactForm: NgForm): void {
    this.displaySuccessMessage = false;
    this.displayErrorMessage = false;

    if (contactForm.valid) {
      this.loading = true;
      this.formSubmitted = false;
      this.mailService.sendContactEmail(
        this.email,
        this.name,
        this.object,
        this.message
      ).subscribe(() => {
        this.loading = false;
        this.displaySuccessMessage = true;
        contactForm.reset();
      }, () => {
        this.loading = false;
        this.displayErrorMessage = true;
      });
    }
  }
}
