<!--Carousel Wrapper-->
<mdb-carousel [isControls]="true" class="carousel slide carousel-fade" [animation]="'fade'" [interval]="3000" [noPause]="true">
  <!--First slide-->
  <mdb-carousel-item *ngFor="let url of welcomePictureUrls">
    <div class="view w-100">
      <img class="d-block w-100" [src]="url"
           alt="First slide">
    </div>
  </mdb-carousel-item>
  <!--/First slide-->
</mdb-carousel>
<!--/.Carousel Wrapper-->
