<div class="contact-page-container">
  <div class="left-panel">
      <h1>Contactez moi</h1>
      <div class="infos">
        <span>Toky Nirina RASOLOHERY</span>
      </div>
      <div class="infos">
        <span>Adresse :</span>
        <span class="pl-2">02 Avenue Lepic - 34070 Montpellier</span>
      </div>
      <div class="infos">
        <span>Tel :</span>
        <span class="pl-2">07 81 69 96 72</span>
      </div>

      <form (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm" [ngClass]="{'formSubmitted': formSubmitted}" class="mt-4">
        <div class="md-form md-outline mt-0">
          <input [(ngModel)]="name" type="text" id="nom" name="nom" class="form-control" [disabled]="loading" required mdbInput>
          <label for="nom">Nom *</label>
        </div>
        <div class="md-form md-outline">
          <input [(ngModel)]="email" type="email" id="email" name="email" class="form-control" [disabled]="loading" required mdbInput email>
          <label for="email">Email *</label>
        </div>
        <div class="md-form md-outline">
          <input [(ngModel)]="object" type="text" id="objet" name="objet" class="form-control" [disabled]="loading" required mdbInput>
          <label for="objet">Objet * </label>
        </div>
        <div class="md-form md-outline">
          <textarea [(ngModel)]="message" type="text" id="message" name="message" class="form-control md-textarea" [disabled]="loading" required mdbInput rows="5"></textarea>
          <label for="message">Message *</label>
        </div>
        <div *ngIf="formSubmitted && !contactForm.valid" class="error-msg">*Champs obligatoires manquants ou invalides.</div>
        <div *ngIf="displayErrorMessage" class="error-msg">Une erreur est survenue lors de l'envoi merci de reessayer plus tard.</div>
        <div *ngIf="displaySuccessMessage" class="success-msg">Merci, votre message a ete envoye avec succes.</div>
        <button (click)="formSubmitted=true;" [disabled]="loading">Envoyer</button>
      </form>
  </div>
  <div class="right-panel">
    <!--Carousel Wrapper-->
    <mdb-carousel [isControls]="true" class="carousel slide carousel-fade" [animation]="'fade'">
      <!--First slide-->
      <mdb-carousel-item *ngFor="let url of welcomePictureUrls">
        <div class="view w-100">
          <img class="d-block w-100" [src]="url"
              alt="First slide">
        </div>
      </mdb-carousel-item>
        <!--/First slide-->
    </mdb-carousel>
    <!--/.Carousel Wrapper-->
  </div>
</div>
