import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portfolio-portrait-page',
  templateUrl: './portfolio-portrait-page.component.html',
  styleUrls: ['./portfolio-portrait-page.component.scss']
})
export class PortfolioPortraitPageComponent implements OnInit {

  public theme = 'portrait';
  public nbImg = 26;

  constructor() { }

  ngOnInit(): void {
  }

}
