import { Component, OnInit, AfterViewChecked, Input, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit, AfterViewChecked {

  @Input() theme: string;
  @Input() nbImg: number;

  @ViewChild('shareButtons') shareButtons: ElementRef;

  public loadingGallery = true;
  public albums = [];
  public srcUrlDisplayed: string;

  constructor(
    private renderer: Renderer2,
    private lightbox: Lightbox
    ) {}

  ngOnInit(): void {
    console.log(this.theme);
    for (let i = 1; i <= this.nbImg; i++) {
      const src = 'https://tnrphotographe.com/assets/pictures/portfolio/portfolio-' + this.theme + '/image-' + i + '.jpg';
      const caption = 'Partager TNR Photographe :';
      const thumb = 'https://tnrphotographe.com/assets/pictures/portfolio/portfolio-' + this.theme + '/thumb-' + i + '.jpg';
      const album = {
        src,
        caption,
        thumb
      };
      this.albums.push(album);
    }
  }

  ngAfterViewChecked(): void {
    setTimeout(() => {
      this.loadingGallery = false;
    }, 5000);
  }


  open(index: number): void {
    // open lightbox
    this.lightbox.open(this.albums, index);
    this.srcUrlDisplayed = this.albums[index].thumb;
    setTimeout(() => {
      this.renderer.appendChild(document.getElementsByClassName('lb-details')[0], this.shareButtons.nativeElement);
    }, 500);
  }

  close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }

}
