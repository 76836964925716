import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  private SERVER_DOMAIN = 'https://app-tnr-photographe-be.herokuapp.com';

  constructor(private http: HttpClient) {}

  public sendContactEmail(
    mailTo: string,
    name: string,
    subject: string,
    message: string): Observable<void> {
      const httpHearders = new HttpHeaders();
      httpHearders.append('Accept', 'application/json');
      httpHearders.append('Content-Type', 'application/json');
      httpHearders.append('Access-Control-Allow-Origin', '*');
      const options = {
        headers: httpHearders
      };
      return this.http.post<any>(`${this.SERVER_DOMAIN}/api/mail`, {
        mailTo,
        name,
        subject,
        message
      }, options).pipe(
        catchError(err => {
          return throwError(err);
        })
      );
    }
}
