<div *showItBootstrap="['xl', 'lg', 'md']" class="d-flex w-100">
  <div class="logo">
    <a routerLink="/accueil" routerLinkActive="active">
      <img src="../../../assets/logos/logo-tnr.svg"/>
    </a>
  </div>
  <div class="menu-items">
    <a routerLink="/accueil" routerLinkActive="active home-page">Accueil</a>
    <a routerLink="/portfolio" routerLinkActive="active">Portfolio</a>
    <a routerLink="/info" routerLinkActive="active">A Propos</a>
    <a routerLink="/contact" routerLinkActive="active">Contact</a>
  </div>
</div>

<div *showItBootstrap="['xs', 'sm']" class="w-100">

    <div class="logo-mobile">
      <a routerLink="/accueil" routerLinkActive="active">
        <img src="../../../assets/logos/logo-tnr.svg"/>
      </a>
      <svg *ngIf="!isHeaderOpen" (click)="isHeaderOpen = true" enable-background="new 0 0 32 32" version="1.1" viewBox="0 0 32 32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path d="m4 10h24c1.104 0 2-0.896 2-2s-0.896-2-2-2h-24c-1.104 0-2 0.896-2 2s0.896 2 2 2zm24 4h-24c-1.104 0-2 0.896-2 2s0.896 2 2 2h24c1.104 0 2-0.896 2-2s-0.896-2-2-2zm0 8h-24c-1.104 0-2 0.896-2 2s0.896 2 2 2h24c1.104 0 2-0.896 2-2s-0.896-2-2-2z"/></svg>
      <svg *ngIf="isHeaderOpen" (click)="isHeaderOpen = false" class="close-menu" enable-background="new 0 0 47.971 47.971" version="1.1" viewBox="0 0 47.971 47.971" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88   c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242   C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879   s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"/></svg>
    </div>
    <div [ngClass]="{'header-menu-active': isHeaderOpen}" class="side-menu">
        <span class="px-3"><a routerLink="/accueil" routerLinkActive="active" (click)="isHeaderOpen = false">Accueil</a></span>
        <span class="px-3"><a routerLink="/portfolio" routerLinkActive="active" (click)="isHeaderOpen = false">Portfolio</a></span>
        <span class="px-3"><a routerLink="/info" routerLinkActive="active" (click)="isHeaderOpen = false">A Propos</a></span>
        <span class="px-3"><a routerLink="/contact" routerLinkActive="active" (click)="isHeaderOpen = false">Contact</a></span>
    </div>
  </div>

