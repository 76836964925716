<div class="galleries-container">
  <div class="photo-section" (click)="goToRoute('portfolio/mariage')">
    <img src="https://tnrphotographe.com/assets/pictures/portfolio/porfolio-home/image-2.jpg"/>
    <div class="portfolio-title">Mariage</div>
  </div>
  <div class="photo-section" (click)="goToRoute('portfolio/portrait')">
    <img src="https://tnrphotographe.com/assets/pictures/portfolio/porfolio-home/image-1.jpg"/>
    <div class="portfolio-title">Portrait</div>
  </div>
  <div class="photo-section" (click)="goToRoute('portfolio/naissance')">
    <img src="https://tnrphotographe.com/assets/pictures/portfolio/porfolio-home/image-3.jpg"/>
    <div class="portfolio-title">Naissance</div>
  </div>
</div>
